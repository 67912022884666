<template>
  <Card title="任务说明管理">
    <div class="right">
      <a-button type="primary" @click="add" :disabled="!permissionAdd">新增</a-button>
    </div>

    <div style="padding-top: 16px"></div>

    <a-table bordered :dataSource="list" :loading="loading" rowKey="id">
      <a-table-column key="type" title="任务类型">
        <template slot-scope="text">
          <DataDictFinder dictType="taskType" :dictValue="String(text.type)" />
        </template>
      </a-table-column>
      <a-table-column key="remark" title="任务说明" data-index="remark" />

      <a-table-column key="control" title="操作" align="center">
        <template slot-scope="text">
          <a-space v-show="permissionEdit">
            <a href="#" @click.prevent="
        edit(text)
        ">编辑</a>
            <a href="#" class="danger" @click.prevent="deleteText(text)">删除</a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>

    <Edit :visible="visible" :detail="detail" @cancel="cancel" @refresh="getList" />
  </Card>
</template>

<script>
import Edit from "./edit.vue";
import request from "@/api/request";
import { mapGetters } from "vuex";
function fetchList() {
  return request({
    url: "/model-analysis/inspect/task/remark/list",
  });
}

function remove(params) {
  return request({
    url: "/model-analysis/inspect/task/remark/delete/" + params.id,
  });
}

export default {
  components: {
    Edit,
  },

  data() {
    return {
      loading: false,
      list: [],

      detail: {},
      visible: false,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList()
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cancel() {
      this.visible = false;
      this.detail = {};
    },

    add() {
      this.visible = true;
    },

    edit(text) {
      this.detail = text;
      this.visible = true;
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          const hide = that.$message.loading("删除中...", 0);
          remove({ id: text.id, category: text.category })
            .then(() => {
              that.getList();
            })
            .finally(() => {
              hide();
            });
        },
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    permissionAdd() {
      return this.hasPermission("model-analysis:inspect:task:remark:save")
    },
    permissionEdit() {
      return this.hasPermission("model-analysis:inspect:task:remark:modify")
    }
  }
};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":_vm.detail.id ? '修改说明' : '新增说明',"visible":_vm.visible,"footer":null,"width":"640px"},on:{"cancel":_vm.cancel}},[_c('div',{staticClass:"form"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 16 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('div',[_c('a-row',[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"任务类型","label-col":{ span: 4 },"wrapper-col":{ span: 20 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'type',
                    {
                      initialValue: _vm.detail.type,
                      rules: [
                        {
                          required: true,
                          message: '请选择',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'type',\n                    {\n                      initialValue: detail.type,\n                      rules: [\n                        {\n                          required: true,\n                          message: '请选择',\n                        },\n                      ],\n                    },\n                  ]"}]},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"任务说明","label-col":{ span: 4 },"wrapper-col":{ span: 20 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'remark',
                    {
                      initialValue: _vm.detail.remark,
                      rules: [
                        {
                          required: true,
                          message: '请输入',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'remark',\n                    {\n                      initialValue: detail.remark,\n                      rules: [\n                        {\n                          required: true,\n                          message: '请输入',\n                        },\n                      ],\n                    },\n                  ]"}]})],1)],1)],1)],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.saveLoading}},[_vm._v("保存")])],1)],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <a-modal
      :title="detail.id ? '修改说明' : '新增说明'"
      :visible="visible"
      :footer="null"
      @cancel="cancel"
      width="640px"
    >
      <div class="form">
        <a-form
          :form="form"
          @submit="handleSubmit"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
          :colon="false"
        >
          <div>
            <a-row>
              <a-col :lg="24" :md="24" :sm="24">
                <a-form-item
                  label="任务类型"
                  :label-col="{ span: 4 }"
                  :wrapper-col="{ span: 20 }"
                >
                  <a-select
                    v-decorator="[
                      'type',
                      {
                        initialValue: detail.type,
                        rules: [
                          {
                            required: true,
                            message: '请选择',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-select-option
                      v-for="item in typeList"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :lg="24" :md="24" :sm="24">
                <a-form-item
                  label="任务说明"
                  :label-col="{ span: 4 }"
                  :wrapper-col="{ span: 20 }"
                >
                  <a-textarea
                    v-decorator="[
                      'remark',
                      {
                        initialValue: detail.remark,
                        rules: [
                          {
                            required: true,
                            message: '请输入',
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>

          <div class="right">
            <a-space>
              <a-button @click="cancel">关闭</a-button>
              <a-button htmlType="submit" type="primary" :loading="saveLoading"
                >保存</a-button
              >
            </a-space>
          </div>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import request from "@/api/request";

function add(data) {
  return request({
    url: "/model-analysis/inspect/task/remark/save",
    method: "post",
    data,
  });
}

function edit(data) {
  return request({
    url: "/model-analysis/inspect/task/remark/modify",
    method: "post",
    data,
  });
}
import { mapGetters } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      saveLoading: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("taskType");
    },
  },

  methods: {
    cancel() {
      this.form.resetFields();
      this.$emit("cancel");
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;

          let task = add;
          if (this.detail.id) {
            task = edit;
          }
          const params = {
            ...values,
          };
          task({
            ...this.detail,
            ...params,
          })
            .then(() => {
              this.cancel();
              this.$emit("refresh");
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  position: fixed;
  top: 12vh;
  right: 4vw;
  z-index: 99;
  width: 40vw;
  background-color: #000;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  height: 75vh;
  overflow: auto;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 998;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.form {
  padding: 1em;

  .center {
    padding: 40px;
  }
}
</style>
